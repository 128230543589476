import colombiacircle from "../assets/flags/colombiacircle.svg"
import usacircle from "../assets/flags/usacircle.svg"
import appstorees from "../assets/badges/appstorewhitees.svg"
import appstoreen from "../assets/badges/appstorewhiteen.svg"
import googleplayes from "../assets/badges/gplayes.png"
import googleplayen from "../assets/badges/gplayen.png"


const common = {
    es: {
        we_are: "Estamos en",
        maintenance: "mantenimiento",
        icon: colombiacircle,
        apple_download_badge: appstorees,
        google_download_badge: googleplayes,
        continue: "Continuar",
        description: "Descripción",
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        email: "Correo electrónico",
        password: "Contraseña",
        email_placeholder: "Ingrese su correo electrónico",
        password_placeholder: "Ingrese su contraseña",
        dont_have_account: "¿No tienes una cuenta?",
        forgot_password: "¿Olvidaste tu contraseña?",
        invalid_login: "Credenciales inválidas",
        reset_psw: "Debido a la actualización, es necesario restablecer su contraseña",
        confirm_password: "Confirmar contraseña",
        confirm_password_placeholder:"Ingrese su contraseña nuevamente",
        register: "Crear cuenta",
        register_success: "Creación de cuenta exitosa",
        register_fail: "Creación de cuenta fallida",
        already_have_account: "¿Ya tienes una cuenta?",
        password_mismatch: "Las contraseñas no coinciden",
        password_too_short: "Al menos 8 caracteres",
        password_needs_uppercase: "Al menos una letra mayúscula",
        password_needs_lowercase: "Al menos una letra minúscula",
        password_needs_number: "Al menos un número",
        password_needs_special: "Al menos un carácter especial",
        hinting_regex_error: "La contraseña no cumple con los requerimientos mínimos",
        complete_the_captcha: "Complete el CAPTCHA",
        register_failed: "Creación de cuenta fallida, intente nuevamente",
        balance_navbar: "Balance total",
        terms_text1: "He leído y estoy de acuerdo con los",
        terms_text2: "de Nemo Latam.",
        terms_and_conditions: "Términos y Condiciones",
        data_processing_policy: "Política de Tratamiento de Datos",
        declaration_text1: "He leído y confirmo la",
        goods_declaration: "Declaración Jurada de Origen de Fondos.",
        authorization_text1: "He leído y confirmo la",
        data_authorization: "Autorización para el tratamiento",
        authorization_text2: "de mis datos personales.",
        confirm_email: "Confirmar correo",
        email_code: "Código de confirmación",
        email_code_text: "Se te ha enviado un correo electrónico con un código, por favor ingréselo a continuación.",
        confirm: "Confirmar",
        cancel: "Cancelar",
        code_placeholder: "Ingrese su código de confirmación",
        resent_code: "Código reenviado",
        email_confirmed: "Correo confirmado exitosamente",
        verify_email: "Verificar correo",
        back_to_login: "Volver a inicio de sesión",
        resend_code: "Reenviar codigo",
        in: "en",
        seconds: "segundos",
        click_to: "Clic para",
        click_here: "Clic aquí",
        incorrect_code: "Código erroneo",
        reset_password: "Restablecer contraseña",
        email_sent: "Correo enviado",
        error: "Error",
        unexpected_error: "Ocurrió un error inesperado",
        invalid_confirmation_code: "Código de confirmación inválido",
        captcha_failed: "Captcha fallido",
        reset_continue_text: "Te hemos enviado un correo con instrucciones sobre cómo proceder con el reinicio de tu contraseña.",
        password_reset_success: "Contraseña actualizada existosamente",
        new_password: "Contraseña nueva",
        confirm_new_password: "Confirmar contraseña nueva",
        new_password_placeholder: "Ingrese su nueva contraseña",
        new_confirm_password_placeholder: "Confirme su contraseña nueva",
        reset_code_expired: "Enlace de reinicio expirado, solicitelo nuevamente",
        reset_password_request: "Solicitud de reinicio de contraseña",
        reset_password_request_button: "Solicitar reinicio de contraseña",
        password_reset_success_text: "Reinicio de contraseña exitoso. Ahora puedes iniciar sesión normalmente.",
        wallet: "Activos",
        converter: "Convertir",
        deposit: "Depositar",
        withdrawal: "Retirar",
        profile: "Perfil",
        modal_deposit: {
            title: "Solicitud de depósito",
            info: "Has creado una solicitud de deposito, por favor sigue los pasos en la nueva pestaña para terminar este proceso",
            description: "En caso no se abrió ninguna, haga click en el enlace de abajo",
            link: "Click aquí",
            button: "Ir a billetera"  
        },
        modal_withdrawal: {
            title: "Solicitud de Retiro",
            info: "Has creado una solicitud de retiro, por favor sigue los pasos en la nueva pestaña para completar este proceso.",
            info_card: "Has creado una solicitud de retiro a tu NEMO Card. Por favor, espera hasta la confirmación para poder usarlo.",
            info_crypto: "Has creado una solicitud de retiro. Por favor, espera hasta la confirmación para ver tu saldo actualizado.",
            description: "Si no se abrió ninguna pestaña, haz clic en el enlace de abajo.",
            link: "Haz clic aquí",
            button: "Ir a la billetera"
        },
        payment_account_modal: {
            title: "Crea una cuenta de pago",
        },
        payment_account_info_modal: {
            title: "Información de la cuenta de pago",
        },
        notFoundPage: {
            title: "Página no encontrada" ,
            subtitle: "La página solicitada no se ha encontrado."
        },
        create_wallet: "Crear billetera",
        create_wallet_title: "Crea tu nueva dirección para  ",
        create_wallet_sub_title: "Crear dirección para la red",
        missing_form_fields : "Falta completar los campos",
        take_me_nemo: "Llévame a NEMO",
        balance: "Balance",
        no_balance: "Sin saldo",
        assets: "Activos",
        total_balance: "Balance total",
        equivalent_balance: "Balance equivalente",
        available_balance: "Balance disponible",
        balance_after_withdrawal: "Balance después del retiro",
        small_balances: "Balances pequeños",
        long_coin_names: {
                COP: "Peso colombiano",
                USDT: "USD Tether",
                ETH: "Ethereum",
                BTC: "Bitcoin",
                PEN: "Sol Peruano",
                USD: "Dólar Estadounidense",
        },
        two_step_verification: "Verificación de dos pasos",
        first_name: "Nombre",
        last_name: "Apellidos",
        full_name: "Nombre completo",
        profile_header: "Mi perfil",
        country: "País",
        phone: "Teléfono",
        coin_price: "Precio moneda",
        cost_price: "Precio costo",
        amount: "Cantidad",
        limits: "Límites",
        limit_overflow: "El monto debe estar entre los límites",
        min_amount: "Cantidad Mínima",
        max_amount: "Cantidad Máxima",
        recieve_amount: "Cantidad a recibir",
        fee: "Comisión",
        min_fee: "Comisión mínima",
        actions: "Acciones",
        h_change: "Cambio 24h",
        coin: "Moneda",
        search_coin: "Buscar moneda",
        card: "Tarjeta",
        bank: "Banco",
        cash: "Efectivo",
        date: "Fecha",
        select_coin: "Selecciona una moneda",
        select_blockchain: "Selecciona una red",
        blockchain: "Red",
        blockchain_currency: "Red",
        select_method: "Selecciona un método",
        select_option: "Selecciona una opción",
        no_option: "No hay opción disponible",
        select_payment_account: "Selecciona una cuenta de pago",
        bank_name: "Nombre del banco",
        account_type: "Tipo de cuenta (C=Cuenta Corriente, A=Caja de Ahorro)",
        account_number: "Número de cuenta",
        phone_number: "Número de célular",
        firstname: "Nombre",
        lastname: "Apellido",
        document_number: "Número de documento",
        document_type: "Tipo de documento",
        nationalId: "Cédula de Ciudadanía",
        foreignerId: "Cédula de Extranjería",
        passport: "Pasaporte",
        chanceryId: "Tarjeta Diplomática",
        ruc: "RUC",
        display_name: "Nombre a mostrar", 
        address: "Dirección",
        city: "Ciudad",
        installments: "Cuotas",
        send: "Enviar",
        insert: "Ingrese su ",
        sucess_copy_address: "Dirección copiada exitosamente",
        error_copy_address: "Hubo un error al realizar la copia de la dirección",
        payment_account_created: "Nueva cuenta de pago creada",
        balance_history: "Balance / Historial",
        selling: "Das",
        getting: "Recibes",
        convert: "Convertir",
        exceeds_balance_more_than_max: "La cantidad seleccionada excede el saldo actual o el tamaño máximo de la orden para el activo a dar",
        less_than_min: "La cantidad seleccionada es menor que el mínimo aceptable para el activo a dar",
        selected_amount_not_available: "La cantidad seleccionada no está disponible, por favor contacte a soporte",
        movements:"Movimientos",
        order_executed: "Orden ejecutada exitosamente",
        traded: "Se intercambió",
        for: "por",
        invalid_address: "Cuenta de pago inválida",
        payouts_freezed: "Los pagos están actualmente congelados debido a un restablecimiento de contraseña.",
        next:"Siguiente",
        previous:"Anterior",
        nomovements:"Todavía no tienes historial de transacciones",
        status_mapping: {
            pending: "Pendiente",
            to_be_sent: "Para enviar",
            done: "Hecho",
            canceled: "Cancelado",
            failed: "Fallido",
            unknown: "Desconocido",
            wait_confirmation: "Confirmando",
            reverted: "Revertido",
            verifying: "Verificando",
          },
        status: "Estado",
        available: "Disponible",
        trade_history: "Historial",
        gave_got: "Diste | Recibiste",
        gave: "Diste",
        got: "Recibiste",
        price: "Precio",
        no_balance_conv: "Balance moneda base es 0",
        fee_apply_t: "Comisión aplicada de",
        fee_equivalent_t: "equivalente a",
        hello_nickname: "Bienvenido,",
        birthday: "Fecha de nacimiento",
        old_password: "Contraseña actual",
        old_password_placeholder: "Ingrese su contraseña actual",
        change_password: "Cambiar contraseña",
        wrong_old_password: "Contraseña actual incorrecta",
        security: "Seguridad",
        two_fa: "Autenticación de dos factores",
        enable: "Habilitar",
        disable: "Deshabilitar",
        two_fa_off: "2FA deshabilitado",
        two_fa_on: "2FA habilitado",
        tfa_explanation: "Escanear código QR con algún app autenticador",
        back: "Volver",
        submit: "Enviar",
        ready: "Listo",
        put_authenticator_otp: "Ingrese el OTP del autenticador",
        tfa_otp_input_placeholder: "XXXXXX",
        otp_failed: "Código erroneo",
        tfa_setup_successful: "Autenticación de 2 factores exitosa",
        tfa_disable_successful: "Autenticación de 2 factores removida exitosamente",
        inswitch_disclaimer: "Tu cuenta bancaria debe tener tu nombre y ser el mismo que usas en esta plataforma.",
        website_under_maintenance: "Plataforma en mantenimiento, estaremos de vuelta en unos momentos.",
        nemocard: "Nemo Card",
        active: "Activada",
        error_refresh_page: "Intenta nuevamente en unos minutos",
        delete: "Eliminar",
        bank_transfer: "Transferencia bancaria",
        cci_number: "CCI",
        title: {
            default: "Compra y Vende Criptomonedas | NEMO Latam",
            "": "Billetera y Balance",
            converter: "Convierte Monedas",
            deposit: "Deposita Cripto y FIAT",
            withdraw: "Retira Cripto y FIAT",
            profile: "Perfil y Configuración",
            kyc: "Verifica tu identidad",
            login: "Inicia sesión para comenzar",
            register: "Crea una cuenta",
            "confirm-register": "Verifica tu correo electrónico",
            "reset-password": "Restablece tu contraseña",
            maintenance: "Página en mantenimiento",
            "*": "No encontrado",
        },
        converter_errors: {
            invalid_value: "El valor ingresado no es un número",
            value_lower_than_min: "El valor ingresado es menor que el mínimo",
            value_higher_than_max: "El valor ingresado excede el saldo actual o el tamaño máximo",
        },
        account_block: "Cuenta bloqueada, por favor contacte a soporte",
    },
    en: {
        we_are: "We are in",
        maintenance: "maintenance",
        icon: usacircle,
        apple_download_badge: appstoreen,
        google_download_badge: googleplayen,
        continue: "Continue",
        description: "Description",
        login: "Log in",
        logout: "Log out",
        email: "Email Address",
        password: "Password",
        email_placeholder: "Enter your email address",
        password_placeholder: "Enter your password",
        dont_have_account: "Don't have an account?",
        forgot_password: "Forgot your password?",
        invalid_login: "Invalid credentials",
        reset_psw: "Due to the update, it is necessary to reset your password.",
        confirm_password: "Confirm password",
        confirm_password_placeholder:"Enter your password again",
        register: "Create account",
        register_success: "Account created successfully",
        register_fail: "Account creation failed",
        already_have_account: "Already have an account?",
        password_mismatch: "Passwords don't match",
        password_too_short: "At least 8 characters",
        password_needs_uppercase: "At least an uppercase letter",
        password_needs_lowercase: "At least a lowercase letter",
        password_needs_number: "At least 1 digit",
        password_needs_special: "At least one special character",
        hinting_regex_error: "Password does not meet minimum requirements",
        complete_the_captcha: "Complete the CAPTCHA",
        register_failed: "Account creation failed, please try again",
        balance_navbar: "Total balance",
        terms_text1: "I have read and agree to the",
        terms_text2: "of Nemo Latam.",
        terms_and_conditions: "Terms and Conditions",
        data_processing_policy: "Data Processing Policy",
        declaration_text1: "I have read and confirm the",
        goods_declaration: "Declaration of Source of Funds.",
        authorization_text1: "I have read and confirm the",
        data_authorization: "Authorization for the processing",
        authorization_text2: "of my personal data.",
        confirm_email: "Confirm email",
        email_code: "Confirmation code",
        email_code_text: "Email with a code has been sent to you, please enter it in the form below",
        confirm: "Confirm",
        cancel: "Cancel",
        code_placeholder: "Enter your email confirmation code",
        resent_code: "Code sent",
        email_confirmed: "Email confirmation successful",
        verify_email: "Verify email",
        back_to_login: "Back to login",
        resend_code: "Resend code",
        in: "in",
        seconds: "seconds",
        click_to: "Click to",
        click_here: "Click here",
        incorrect_code: "Incorrect code",
        reset_password: "Reset password",
        email_sent: "Email sent",
        error: "Error",
        unexpected_error: "An unexpected error occurred",
        invalid_confirmation_code: "Invalid confirmation code",
        captcha_failed: "Captcha failed",
        reset_continue_text: "We have sent you an email with instructions on how to proceed with your password reset.",
        password_reset_success: "Password reset successfully",
        new_password: "New password",
        confirm_new_password: "Confirm new password",
        new_password_placeholder: "Enter your new password",
        new_confirm_password_placeholder: "Confirm your new password",
        reset_code_expired: "Reset link expired, request a new one",
        reset_password_request: "Password reset request",
        reset_password_request_button: "Request password reset",
        password_reset_success_text: "Password reset successful. You may now log in normally.",
        wallet: "Wallet",
        converter: "Converter",
        deposit: "Deposit",
        withdrawal: "Withdrawal",
        profile: "Profile",
        modal_deposit: {
            title: "Deposit Request",
            info: "You have created a deposit request, please follow the steps in the new tab to complete this process.",
            description: "If no tab was opened, click the link below.",
            link: "Click here",
            button: "Go to wallet"
        },
        modal_withdrawal: {
            title: "Withdrawal Request",
            info: "You have created a withdrawal request, please follow the steps in the new tab to complete this process.",
            info_card: "You have created a withdrawal request to your NEMO Card. Please wait for confirmation before you can use it.",
            info_crypto: "You have created a withdrawal request. Please wait until confirmation to see your balance updated",
            description: "If no tab was opened, click the link below.",
            link: "Click here",
            button: "Go to wallet"
        },
        payment_account_modal: {
            title: "Create payment account",
        },
        payment_account_info_modal: {
            title: "Payment account info",
        },
        notFoundPage: {
            title: "Page not found" ,
            subtitle: "The page you requested could not be found."
        }, 
        create_wallet: "Create wallet",
        create_wallet_title: "Create new address for",
        create_wallet_sub_title: "Create address for the network",
        missing_form_fields : "Missing form fields",
        take_me_nemo: "Take me to NEMO",
        balance: "Balance",
        no_balance: "No balance",
        assets: "Assets",
        total_balance: "Total balance",
        equivalent_balance: "Equivalent balance",
        available_balance: "Available Balance",
        balance_after_withdrawal: "Balance after withdrawal",
        small_balances: "Small balances",
        long_coin_names: {
                COP: "Colombian peso",
                USDT: "USD Tether",
                ETH: "Ethereum",
                BTC: "Bitcoin",
                PEN: "Peruvian Sol",
                USD: "US Dollar",
        },
        two_step_verification: "2-Step verification",
        first_name: "First name",
        last_name: "Last name",
        full_name: "Full name",
        profile_header: "My profile",
        country: "Country",
        coin_price: "Coin price",
        cost_price: "Cost price",
        amount: "Amount",
        limits: "Limits",
        limit_overflow: "Amount should between the limits",
        min_amount: "Min amount",
        max_amount: "Max amount",
        recieve_amount: "Receive amount",
        fee: "Fee",
        min_fee: "Min fee",
        actions: "Actions",
        h_change: "24h change",
        coin: "Coin",
        search_coin: "Search coin",
        card: "Card",
        bank: "Bank",
        cash: "Cash",
        date: "Date",        
        select_coin: "Select a coin",
        select_blockchain: "Select a network",
        blockchain: "Blockchain",
        blockchain_currency: "Blockchain",
        select_method: "Select a method",
        select_option: "Select an option",
        no_option: "No option available",
        select_payment_account: "Select a payment account",
        bank_name: "Bank name",
        account_type: "Account Type (C=Checking Account, A=Savings Account)",
        account_number: "Account number", 
        phone_number: "Phone number",
        firstname: "First Name",
        lastname: "Surname",
        document_number: "Document Number",
        document_type: "Document Type",
        nationalId: "Citizenship ID",
        foreignerId: "Foreigner ID",
        passport: "Passport",
        chanceryId: "Diplomatic ID",    
        ruc: "RUC",
        display_name: "Display Name", 
        address: "Address",
        city: "City",
        phone: "Phone",
        installments: "Installments",
        send: "Send",
        insert: "Insert your ",
        success_copy_address: "Address copied successfully",
        error_copy_address: "There was an error copying the address",
        payment_account_created: "New payment account created",
        balance_history: "Balance / History",
        selling: "Give",
        getting: "Get",
        convert: "Convert",
        exceeds_balance_more_than_max: "Selected amount exceeds balance or max order size for base asset",
        less_than_min: "Selected amount is less than the order minimum for base asset",
        selected_amount_not_available: "Selected amount is not available, please contact support",
        order_executed: "Order executed successfully",
        traded: "Traded",
        for: "for",
        invalid_address: "Invalid payment account",
        payouts_freezed: "Payouts are currently frozen due to a password reset.",
        next: "Next",
        previous:"Previous",
        movements:"Movements",
        all_coins:"All Coins",
        nomovements:"You have no transaction history",
        status_mapping:{
            pending: "Pending" ,
            to_be_sent: "To be sent",
            done: "Done",
            canceled: "Cancelled",
            failed: "Failed",
            unknown: "Unknown",
            wait_confirmation: "Confirming",
            reverted: "Reverted",
            verifying: "Verifying",
        },
        status: "Status",
        available: "Available",
        trade_history: "Trade history",
        gave_got: "Gave | Got",
        gave: "Gave",
        got: "Got",
        price: "Price",
        no_balance_conv: "Base asset balance is 0",
        fee_apply_t: "Applied fee of",
        fee_equivalent_t: "totaling",
        hello_nickname: "Welcome,",
        birthday: "Birthday",
        old_password: "Current password",
        old_password_placeholder: "Enter your current password",
        change_password: "Change password",
        wrong_old_password: "Wrong current password",
        security: "Security",
        two_fa: "Two factor authentication",
        enable: "Enable",
        disable: "Disable",
        two_fa_off: "2FA disabled",
        two_fa_on: "2FA enabled",
        tfa_explanation: "Scan QR code using an authenticator app",
        back: "Back",
        submit: "Submit",
        ready: "Ready",
        put_authenticator_otp: "Enter authenticator OTP",
        tfa_otp_input_placeholder: "XXXXXX",
        otp_failed: "Wrong code",
        tfa_setup_successful: "2 factor authentication setup successful",
        tfa_disable_successful: "2 factor authentication removed successfully",
        inswitch_disclaimer: "Your bank account must have your name and be the same one you use on this platform.",
        website_under_maintenance: "Exchange under maintenance, we will be back shortly.",
        nemocard: "Nemo Card",
        active: "Active",
        error_refresh_page: "Try again in a few minutes",
        delete: "Delete",
        bank_transfer: "Bank transfer",
        cci_number: "CCI",
        title: {
            default: "Buy & Sell Crypto | NEMO Latam",
            "": "Wallet & Balance",
            converter: "Convert Coins",
            deposit: "Deposit Crypto & FIAT",
            withdraw: "Withdraw Crypto & FIAT",
            profile: "Profile & Settings",
            kyc: "Verify your identity",
            login: "Log in to get started",
            register: "Create an account",
            "confirm-register": "Verify your email address",
            "reset-password": "Reset your password",
            maintenance: "Site under maintenance",
            "*": "Not Found",
        },
        converter_errors: {
            invalid_value: "The input value is not a number",
            value_lower_than_min: "The input value is lower than the minimum",
            value_higher_than_max: "The input value exceeds the current balance or maximum size",
        },
        account_block: "Account blocked, please contact support",
    }
    
}

export default common





